* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

:root {
  --dark-color: #161616;
  --monitoring-green: #00e000;
  --input-green: #00b800;
  --accent-color: #ffffff;
}

html {
  height: 100%;
}

body {
  background-color: var(--dark-color);
  color: white;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: auto;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
  padding: 1rem;
  outline: none;
  background-color: rgb(189, 189, 189, 0.1);
  border-radius: 2rem;
  border: none;
  color: white;
  font-size: 16px;
  text-align: center;
  margin: 1rem 0;
  width: 100%;
  -webkit-appearance: none;
}

input.darkInput {
  color: black;
}

input.darkInputSmaller {
  color: black;
  max-width: 500px;
}

.w-95p {
  width: 95%;
  margin: auto;
}

.max-35r h2 {
  margin: 1rem 0;
}

.marginTop {
  margin: 1rem 0 0 0;
}

.eventLogHeading {
  display: flex;
  align-items: center;
}

.logsBtn {
  font-size: 1.4rem;
  margin: 0.5rem 0;
  cursor: pointer;
}

.timePicker {
  text-decoration: none;
  background: none;
  color: white;
  outline: none;
  width: 50px;
  text-align: center;
  border-style: solid;
  border-color: white;
  border-width: 0.15rem;
  border-radius: 1rem;
  padding: 0.1rem 0.2rem;
  margin: 0 0.2rem;
}

.timePickerActiveSelection {
  color: white;
  border-style: solid;
  border-radius: 1rem;
  border-width: 0.15rem;
  padding: 0.1rem 1rem;
  margin: 0 0.2rem;
  cursor: pointer;
  user-select: none;
}

.timePickerActiveSelection.selected {
  color: black;
  background-color: white;
}

.datePickerContainer {
  width: 95%;
  max-width: 600px;
  margin: 1rem auto;
}

.datePickerMonthSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 250px;
  margin: auto;
  justify-content: space-evenly;
}

.calendarHeading {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 1rem auto;
  user-select: none;
}

.calendarGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendarGridCell {
  padding: 0.8rem;
  margin: 0.1rem;
  border-style: solid;
  border-width: 0.1rem;
  border-color: rgb(215, 215, 215);
  text-align: center;
  cursor: pointer;
}

.calendarGridCellInRange {
  border-color: var(--monitoring-green);
}

.currentDayOnCalendar {
  color: var(--monitoring-green);
  font-weight: bold;
}

.calendarArrow {
  cursor: pointer;
  font-size: 1.3rem;
}

.calendarDayLabel {
  margin: 1rem 0.5rem;
  font-weight: bold;
  text-align: center;
  user-select: none;
}

.homeContainer {
  text-align: center;
  padding: 2rem 0;
  width: 95%;
  margin: auto;
}

.homeSection {
  background-color: rgba(135, 135, 135, 0.15);
  border-radius: 10px;
  margin: 2rem 0;
  padding: 0 2rem;
}

.homeHeading {
  font-size: 35px;
  padding: 1rem 0;
}

.homeSubHeading {
  font-size: 24px;
  padding: 2rem 0 1rem 0;
  font-style: italic;
}

.homeTierHeading {
  font-size: 30px;
  padding: 1rem 0 0 0;
  font-weight: bold;
}

.homeBodyText {
  padding: 2rem 0;
  font-size: 20px;
}

.homeBodyTextNoPadding {
  font-size: 20px;
}

.homeButton {
  font-size: 1.3rem;
  text-align: center;
  width: 20rem;
  padding: 0.5rem;
  margin: 1rem auto;
  cursor: pointer;
  background-color: black;
  color: white;
  border-radius: 2rem;
}

.loginButton {
  text-decoration: none;
  font-size: 1.3rem;
  text-align: center;
  width: 20rem;
  padding: 0.5rem;
  margin: 1rem auto;
  cursor: pointer;
  background-color: black;
  color: white;
  border-radius: 2rem;
  display: block;
}

.homeBodyTextTop {
  padding: 2rem 0 1rem 0;
  font-size: 20px;
}

.homeBodyTextTop2 {
  padding: 2rem 0 0 0;
  font-size: 20px;
}

.nfpaResultFlex {
  display: flex;
  margin: 0.5rem 0.2rem;
}

.margin-lr {
  margin: 0rem 1rem;
}

.logsFilterBottomButton {
  cursor: pointer;
  margin: 1rem auto;
  border-style: solid;
  border-color: white;
  border-width: 0.15rem;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  text-align: center;
  max-width: 300px;
}

.levelsFlex {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.spaceBtn {
  margin: 0 1rem;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  color: black;
}

.checkbox {
  color: rgb(92, 92, 92);
  margin: 0 0.5rem;
  font-size: 1.2rem;
}

.homeBodyTextMid {
  padding: 1rem 0;
  font-size: 20px;
}

.homeBodyTextBottom {
  padding: 1rem 0 2rem 0;
  font-size: 20px;
}

.homePic {
  width: 100%;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}

.homePhonePic {
  width: 100%;
  max-width: 340px;
}

.emailSignUpContainer {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.emailSubHeading {
  margin: 1rem 0 2rem 0;
}

.emailBtn {
  text-decoration: none;
  color: black;
  background-color: white;
  border: none;
  padding: 1rem 0;
  width: 50%;
  border-radius: 20px;
  margin: 1rem 0;
  font-size: 0.8rem;
}

.tb-1r {
  padding: 1rem 0;
}

.monitoringGreen {
  color: var(--monitoring-green);
}

.mainBtnGreen {
  width: 100%;
  background: none;
  border-style: solid;
  border-color: var(--monitoring-green);
  color: var(--monitoring-green);
  border-radius: 2rem;
  padding: 1rem 3rem;
  cursor: pointer;
  font-size: 16px;
}

.btnIcon {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 2rem;
  margin: 0 1rem;
}

.iconBtn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 1.3rem;
  margin: 0 1rem;
}

.mainContent {
  transition: filter 0.3s ease;
}

.mainContent.blurred {
  filter: blur(5px);
}

.menu {
  position: fixed;
  top: 70;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 1000;
  display: none;
}

.menu.visible {
  display: block;
}

.menuButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 1.5rem;
  margin: 0 1rem;
}

.menuContainer {
  width: 95%;
  max-width: 700px;
  margin: auto;
  padding: 2rem 0;
  text-align: center;
}

.menuHeading {
  font-size: 1.5rem;
  font-weight: bold;
}

.iconBtn2 {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 0 1rem;
}

.iconBtn3 {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 1.3rem;
}

.borderBottomButton {
  background: none;
  color: inherit;
  border: none;
  border-bottom: solid;
  border-width: 0.15rem;
  padding: 0.3rem 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 1.3rem;
  margin: 0 1rem;
}

.logo {
  width: 12rem;
  padding-top: 3rem;
  margin: auto;
}

.homeLogo {
  max-width: 350px;
}

.loginLogo {
  max-width: 250px;
  margin: 1.5rem 0;
}

.logoTop {
  width: 12rem;
}

.topBarWrapper {
  color: white;
  width: 95%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  /* padding: 1rem 0; */
  height: 70px;
}

.updateSetPointWrapper input {
  max-width: 200px;
}

.iconWrapper button {
  background: none;
  border: none;
  outline: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.churnToggle select {
  outline: none;
}

.errorMsg {
  background-color: red;
  color: white;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  width: 75%;
  text-align: center;
  margin: auto;
}

.logOutBtn {
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border-style: solid;
  border-color: var(--dark-color);
  font-size: 0.8rem;
  cursor: pointer;
  color: black;
}

.eventFilter {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filterPicker {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBtn {
  background-color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 1rem;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  color: black;
  margin-top: 1.5rem;
  text-align: center;
}

.loader {
  text-align: center;
  margin: 10rem 0;
}

.smallLoader {
  margin: 1.5rem 0;
  text-align: center;
}

.margin-t-b-h {
  margin: 0.5rem 0;
}

.margin-t-b-1 {
  margin: 1rem 0;
}

.margin-t-b-2 {
  margin: 2rem 0;
}

.margin-t-b-3 {
  margin: 3rem 0;
}

.margin-t-b-4 {
  margin: 4rem 0;
}

.margin-t-b-5 {
  margin: 5rem 0;
}

.margin-r-h {
  margin: 0 0.5rem 0 0;
}

.margin-r-1 {
  margin: 0 1rem 0 0;
}

.loadMoreBtn {
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border-style: solid;
  border-color: var(--dark-color);
  font-size: 0.8rem;
  cursor: pointer;
  margin: auto;
  color: black;
}

.centeredText {
  text-align: center;
}

.logOutBtn:hover {
  background-color: var(--dark-color);
  border-color: white;
  color: white;
}

.addEmail button {
  margin: 1.3rem 0;
}

.addEmailWrapper h2 {
  text-align: center;
}

.addEmail input {
  width: 100%;
  border-style: none;
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0;
  border-radius: 1rem;
}

.addEmail input:focus {
  outline: none;
}

.sectionSpacer {
  padding: 0.5rem 0;
}

.margin1 {
  margin: 1rem;
}

.lineSpacer {
  margin: 1rem 0;
}

.sectionSpacer2 {
  padding: 1rem 0;
}

.sectionSpacer3 {
  margin: 3rem 0;
}

.sectionSpacer4 {
  padding: 0.3rem 0;
}

.reportSectionHeading {
  font-size: 1.2rem;
  font-weight: bold;
  border-style: solid;
  border-width: 0.13rem;
  padding: 0.1rem 0.5rem;
  border-color: black;
  margin: 0.2rem 0;
}

.report-spacer-line {
  padding: 0.1rem;
  border-style: solid;
  border-width: 0.1rem;
  border-color: black;
  margin: 1rem 0;
}

.flowSectionHeading {
  font-size: 1.25rem;
  font-weight: bold;
  padding: 1rem 0;
}

.flex-space-out {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inventoryItemHeading {
  font-size: 1.3rem;
  margin: 0 0 0.3rem 0;
  font-weight: bold;
}

.inventoryItemLocationsHeading {
  font-size: 1.2rem;
  font-weight: bold;
}

input[type="number"].inventoryQuantityPicker {
  width: 100px;
  margin: 0;
  height: 10px;
}

.salesCallButton {
  cursor: pointer;
  width: 100px;
  background-color: rgb(215, 215, 215);
  padding: 0.5rem;
  text-align: center;
  border-radius: 2rem;
  margin: 0.5rem 1rem;
}

.salesCallButtonDark {
  cursor: pointer;
  width: 100px;
  background-color: rgb(146, 146, 146);
  padding: 0.5rem;
  text-align: center;
  border-radius: 2rem;
  margin: 0.5rem 1rem;
}

.salesCallButtonGreen {
  cursor: pointer;
  width: 100px;
  background-color: rgb(11, 219, 0);
  padding: 0.5rem;
  text-align: center;
  border-radius: 2rem;
  margin: 0.5rem 1rem;
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.flex-btns {
  display: flex;
  flex-direction: row;
}

.flex-align-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-align-row-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.flex-align-row-responsive {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editor-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  margin: auto;
}

.editorNumInput {
  max-width: 300px;
}

.reviewerTextArea {
  height: 200px;
  text-align: left;
  resize: none;
}

.editorWrapper {
  max-width: 300px;
}

.flow-section-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 20rem;
}

.flowSubHeading {
  font-size: 1.05rem;
  font-weight: bold;
}

.pumpRoomWrapper {
  padding: 0 0 2rem 0;
}

.pumpRoomHeading {
  display: flex;
  align-items: center;
}

.spacedOutHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}

.fileIcon {
  font-size: 2rem;
  margin: 0 1rem;
}

.folderListCell {
  max-width: 700px;
  margin: auto;
  color: white;
}

.folderListCellContents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.folderListItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.folderListLabel {
  display: flex;
  align-items: center;
}

.folderListBtns {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pumpRoomHeading2 {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0.5rem 0 0.5rem 1rem;
}

.margin2 {
  margin: 2rem;
}

.marginLrHalf {
  margin: 0 0.5rem;
}

.notePriority {
  font-size: 0.9rem;
  margin: 0.1rem 0;
}

.sigImg {
  height: 125px;
  width: auto;
}

.marginlr {
  margin: 0 1rem;
}

.folderBtn {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
}

.docName {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.noStyle {
  text-decoration: none;
  color: white;
}

.newFolderBtn {
  font-size: 2rem;
  margin: 0 1rem;
  cursor: pointer;
}

.renameFolderBtn {
  font-size: 1.6rem;
  margin: 0 1rem;
  cursor: pointer;
}

.trashBtn {
  font-size: 1.75rem;
  margin: 0 1rem;
  cursor: pointer;
}

.red {
  color: red;
}

.green {
  color: rgb(0, 186, 0);
}

.gray {
  color: gray;
}

.amber {
  color: rgb(255, 162, 0);
}

.review-btn {
  margin: 0 1rem;
  border-color: #ff009d;
  border-style: solid;
  border-radius: 20px;
  color: #ff009d;
  padding: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.laminate-btn {
  margin: 0 1rem;
  border-color: #00d9ff;
  border-style: solid;
  border-radius: 20px;
  color: #00d9ff;
  padding: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.reviewHeading {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 2rem 0;
}

.reviewSubHeading {
  font-weight: bold;
  margin: 1rem 0 1.5rem 0;
}

.renameRoomBtn {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  margin: 0 1rem;
  cursor: pointer;
}

.newRoomBtn {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  margin: 0 1rem;
  cursor: pointer;
  width: 12rem;
  text-align: center;
  margin: 1rem 0.5rem;
}

.recentlyAdded {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.max700 {
  width: 95%;
  max-width: 700px;
  margin: auto;
}

.usersBtn {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.completeBtn {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.renameRoomBtn2 {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
}

.roomOptions {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
}

.adminControllerLabel {
  font-size: 1.1rem;
  padding-bottom: 0.3rem;
  background-color: var(--dark-color);
  color: white;
  border: none;
  cursor: pointer;
  width: 150px;
}

.listLink {
  text-decoration: none;
}

.roomListCell {
  max-width: 700px;
  margin: auto;
  color: white;
  cursor: pointer;
}

.dashboardWrapper {
  width: 95%;
  max-width: 700px;
  margin: auto;
}

.dashboardHeading {
  font-size: 1.9rem;
  padding: 1rem 0 1.5rem 0;
}

.roomListText {
  text-decoration: none;
  color: white;
  padding: 0.1rem 0;
}

.roomListTitle {
  font-size: 1.6rem;
  text-decoration: none;
  color: white;
  padding: 0 0 0.2rem 0;
}

.listLineTop {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
  max-width: 700px;
  margin: auto;
  margin-bottom: 0.5rem;
}

.listLineTopWide {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
  margin-bottom: 0.5rem;
}

.listLineTop2 {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
  max-width: 700px;
  margin: auto;
  margin-bottom: 0.5rem;
}

.listLineTop2 {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
  margin-bottom: 0.5rem;
}

.listLineTop3 {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.eventMessage {
  margin: 1rem 0;
  font-size: 1.15rem;
  font-weight: bold;
}

.listLine {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
  margin: 0.5rem 0;
}

.listLine2 {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
  width: 100%;
  margin: 1rem 0;
}

.churnTestView {
  background-color: white;
  color: black;
  position: absolute;
  top: 0;
  /* bottom: 0; */
  left: 0;
  right: 0;
  /* max-width: 1000px; */
  /* min-height: 1000px; */
}

.churnTestReportView {
  background-color: white;
  color: black;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* max-width: 1000px; */
  /* min-height: 1000px; */
}

.churnTestContent {
  max-width: 1000px;
  margin: auto;
}

.serviceReportHeading {
  font-size: 1.1rem;
}

.serviceReportContent {
  max-width: 1000px;
  margin: auto;
  min-height: 900px;
}

.serviceReportSectionHeading {
  font-weight: bold;
  margin: 0.25rem 0;
}

.serviceReportSection {
  margin: 0.5rem 0;
  border-color: black;
  border-style: solid;
  border-width: 0.15rem;
  padding: 0.5rem;
  border-radius: 1rem;
}

.bold {
  font-weight: bold;
}

.marginR {
  margin-right: 2rem;
}

.rosemontLogo {
  max-width: 205px;
  margin: 0.2rem 0;
}

.siteName {
  font-weight: bold;
}

.blue {
  color: blue;
}

.churnTestBottom {
  width: 100%;
  /* max-width: 500px; */
  text-align: center;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5rem 0 3rem 0;
}

.churnTestReportBottom {
  width: 100%;
  /* max-width: 500px; */
  text-align: center;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20rem 0 3rem 0;
}

.invisible {
  opacity: 0;
}

.churnTestSection {
  margin: 0.4rem;
}

.motorRunReportSection {
  margin: 1rem 0;
}

.reportItem {
  margin: 0.5rem 0;
}

.churnTestHeadingSection {
  margin: 0.3rem 0;
  display: flex;
  flex-direction: row;
}

.exitChurn {
  background-color: black;
  color: white;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.7rem;
  margin: 2rem 1rem;
  max-width: 100px;
  text-align: center;
}

.reportFlex {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-evenly;
  margin: 1rem 0;
}

.reportFlexItem {
  display: flex;
  flex-direction: row;
}

.spacerHalf {
  margin: 0.5rem 0;
}

.reportReviewItem {
  margin: 1rem 0;
}

.reviewAnswerFlex {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
}

.reportFlexHeading {
  font-weight: bold;
  margin: 0 0.3rem;
}

.ctGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.remoteLogo {
  max-width: 215px;
}

.filterHeading {
  padding: 0.5rem 0 1rem 0;
  font-size: 1.1rem;
}

.plainPicker {
  background: none;
  border: solid;
  border-color: white;
  border-radius: 20px;
  color: white;
  padding: 0.1rem 1rem;
  outline: none;
  margin: 0 0.5rem;
  font-size: 1.3rem;
  -webkit-appearance: none;
}

.pumpRoomSection {
  border-top: solid;
  border-color: rgba(205, 205, 205, 0.41);
  padding: 1rem;
  border-width: 0.1rem;
}

.pumpRoomSectionRed {
  border-style: solid;
  border-color: red;
  padding: 1rem;
  border-width: 0.15rem;
  border-radius: 1rem;
}

.remoteSection {
  border-style: solid;
  border-color: white;
  padding: 1rem;
  border-width: 0.15rem;
  border-radius: 1rem;
}

.hiddenInput {
  opacity: 0;
  position: "absolute";
  left: "-9999";
}

.imageShower {
  width: 100%;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}

.imagesWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}

.imageWrapper {
  width: 300px;
  height: 300px;
  overflow: hidden;
  margin: 0.5rem;
  text-align: center;
  cursor: pointer;
}

img {
  width: 100%;
  max-width: 500px;
  padding: 0.5rem;
}

.arrowIconBtn {
  background: none;
  color: inherit;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: none;
}

.arrowIcon {
  font-size: 2rem;
  margin: 0 1rem 0 0;
}

.toggle-bar {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  border: solid;
  border-radius: 20px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.editor-toggle-bar {
  display: flex;
  justify-content: space-evenly;
  border: solid;
  border-radius: 20px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 500px;
}

.toggle-bar-short {
  display: flex;
  justify-content: space-evenly;
  max-width: 300px;
  margin: auto;
  border: solid;
  border-radius: 20px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.toggleItem {
  width: 50%;
  text-align: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 20px;
}

.currentToggleItem {
  width: 50%;
  text-align: center;
  background-color: rgba(71, 71, 71, 0.9);
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 20px;
}

.left-text {
  text-align: left;
}

.currentToggleItemGreen {
  width: 50%;
  text-align: center;
  background-color: var(--input-green);
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 20px;
}

.deleteImgBtn {
  color: white;
  background-color: red;
  border: none;
  border-radius: 20px;
  margin: 0 0.5rem;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  text-align: center;
  width: 50%;
}

.deleteBtn {
  color: white;
  background-color: red;
  padding: 0.3rem 1rem;
  border-radius: 2rem;
  margin: 0 1rem;
}

.button {
  padding: 0.3rem 1rem;
  border-radius: 2rem;
  margin: 0 1rem;
  border: none;
  cursor: pointer;
}

.whiteBtn {
  color: black;
  background-color: white;
}

.redBtn {
  color: white;
  background-color: red;
}

.cancelBtn {
  color: black;
  background-color: white;
  padding: 0.3rem 1rem;
  border-radius: 2rem;
}

.addContBtnWrapper {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
  padding: 1rem 0;
}

.addContact {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 2rem 0;
}

.addContact h2 {
  padding-bottom: 1rem;
}

.contactName {
  font-size: 20px;
  font-weight: bold;
  padding: 0.4rem 0;
}

.contactInfo {
  padding: 0.1rem 0;
}

.uploadBtn2 {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  width: 120px;
  text-align: center;
  margin: 1rem 0;
}

.imageShowerContainer {
  text-align: center;
}

.imageBackBtn {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  text-align: center;
  margin: 1rem 0;
}

.roomOptionsBtn {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  width: 120px;
  text-align: center;
}

.timePickerWrapper {
  position: relative;
  display: inline-block;
}

.timePickerContainer {
  position: relative;
  display: inline-block;
}

.timePickerOptions {
  position: absolute;
  top: 100%;
  background-color: rgb(0, 0, 0);
  padding: 0.2rem 0.1rem;
  border-radius: 1rem;
  z-index: 10;
}

.timePickerOption {
  background-color: rgba(92, 92, 92, 0.347);
  padding: 0.5rem 1rem;
  text-align: center;
  margin: 0.2rem 0;
  border-radius: 0.5rem;
  cursor: pointer;
}

.timePickerOption.selected {
  color: var(--monitoring-green);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  /* width: 300px;
  height: 200px; */
  /* background-color: #fff; */
  /* padding: 20px; */
  width: 95%;
  height: 95%;
  /* position: relative; */
}

.chartContainer {
  width: 100%;
  height: 75%;
  /* max-height: 75%; */
  /* background-color: red; */
}

.barGraphContainer {
  width: 100%;
  height: 75%;
  /* max-height: 75%; */
  /* background-color: red; */
}

.chartTopSectionItem {
  border-bottom: solid;
  border-color: white;
  border-width: 0.15rem;
  margin: 0.5rem 1rem;
}

.chartBackButton {
  cursor: pointer;
  margin: 0 1rem;
  border-style: solid;
  border-color: white;
  border-radius: 1rem;
  border-width: 0.15rem;
  width: 75px;
  text-align: center;
  padding: 0.5rem 1rem;
}

.disableDeleteMode {
  color: white;
  background-color: red;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  width: 150px;
  text-align: center;
}

.roomTabs {
  width: 100%;
  height: 4rem;
  display: flex;
  color: black;
  cursor: pointer;
  justify-content: space-evenly;
  align-items: center;
}

.tabIcon {
  font-size: 32px;
  color: white;
}

.selectedTabIcon {
  font-size: 32px;
  color: var(--monitoring-green);
}

.taskContent {
  margin: 0.3rem 0 0 0;
}

.warningBox {
  width: 50%;
  border: solid;
  border-width: 0.2rem;
  padding: 1rem;
  border-radius: 1rem;
}

.warningQuestion {
  color: red;
}

.warningButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.warningButtons button {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 0.2rem 0.5rem;
  margin: 1rem 0.5rem 0.5rem 0.5rem;
  cursor: pointer;
}

.incompleteTaskStatus {
  margin: 1rem 0;
}

.completedTaskStatus {
  color: var(--monitoring-green);
  margin: 1rem 0;
}

.updateContBtn {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  margin: 0.5rem 2rem;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.updateEqBtn {
  color: black;
  background-color: white;
  border: none;
  border-radius: 20px;
  margin: 0.5rem 1rem;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.deleteContBtn {
  color: white;
  background-color: red;
  border: none;
  border-radius: 20px;
  margin: 0.5rem 2rem;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.backBtn {
  background: none;
  color: inherit;
  border: none;
  padding-bottom: 0.5rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 1.7rem;
  margin: 0 1rem;
}

.dashboardIconsWrapper button {
  background: none;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
}

.controllerInfo {
  color: white;
  font-size: 1.5rem;
}

.pathDisplay {
  display: flex;
  align-items: center;
}

.pad-flex-items {
  padding: 0 2.5rem;
}

.roomsWrapper {
  width: 95%;
}

.basicDashboardHeading h2 {
  padding: 0.5rem 0;
}

.checkBox {
  margin: 0 0.3rem 0 0;
}

.centerText {
  text-align: center;
  margin: 5rem 0;
}

.middleContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

.middleContainer-2 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

.middleContainer3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.middleContainer3 h1 {
  font-size: 1.6rem;
  margin: 1rem 0;
}

.exploreContainer {
  width: 95%;
  max-width: 900px;
  margin: auto;
}

.exploreWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  padding-bottom: 5rem;
  margin: auto;
}

.exploreWrapper h2 {
  margin: 2rem 0;
  width: 100%;
  text-shadow: 2px 2px 5px rgba(86, 86, 86, 0.172);
}

.homeH1 {
  text-shadow: 2px 2px 5px rgba(86, 86, 86, 0.28);
}

.widePic {
  max-width: none;
  width: 100%;
  height: auto;
  display: block;
  margin: 1rem 0;
}

.exploreBodyText {
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
  justify-content: left;
  width: 100%;
}

.exploreList {
  width: 100%;
  justify-content: left;
  margin: 0 0 1rem 1.5rem;
}

.exploreListItem {
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0;
}

.exploreVideo {
  width: 100%;
  margin: 1rem 0 2rem 0;
}

.exploreTopFlex {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.exploreInputLabel {
  max-width: 500px;
  width: 100%;
  justify-content: left;
  margin: 1rem 0 0 0;
}

.exploreLogo {
  max-width: 250px;
}

.exploreDarkSection {
  background-color: var(--dark-color);
  color: white;
  width: 100%;
  padding: 0.5rem;
  margin: 1.5rem 0 1rem 0;
}

.questions-box {
  text-align: left;
}

.yesNoWrapper button {
  border-style: solid;
  border-color: white;
  border-radius: 1rem;
  background: none;
  color: white;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin: 2rem 1rem;
  padding: 1rem 3rem;
}

.churnTestWrapper button {
  background-color: black;
  border: none;
  outline: none;
  border-radius: 1rem;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 2rem 0;
}

.churnBtnWrapper button {
  margin: 0.5rem 0;
}

.psiContainer {
  margin: auto;
  width: 100%;
}

.systemPSIsectionWrapper {
  text-align: center;
  margin: auto;
  padding: 1rem 0;
  display: flex;
  justify-content: space-evenly;
}

.modbusFlex {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.systemPSIsectionWrapper h2 {
  padding: 0 0 1.5rem 0;
}

.smallPSI {
  font-size: 1.3rem;
  text-align: center;
}

.setPointLabel {
  font-size: 1.3rem;
  text-align: center;
  margin: 0 0.5rem;
}

.video-container {
  margin: 5rem 0;
}

.greenSystemPSI {
  font-size: 3.2rem;
  text-align: center;
  color: var(--monitoring-green);
}

.flex-input-cell {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 1rem 0;
}

.redSystemPSI {
  font-size: 3.2rem;
  text-align: center;
  color: red;
}

.yellowSystemPSI {
  font-size: 3.2rem;
  text-align: center;
  color: yellow;
}

.systemPSIlabel {
  font-size: 1.5rem;
}

.redSystemPSIlabel {
  font-size: 1.5rem;
  color: red;
}

.yellowSystemPSIlabel {
  font-size: 1.5rem;
  color: yellow;
}

.okStatus {
  padding: 0.2rem 0;
}

.okStatusGreen {
  padding: 0.2rem 0;
  color: var(--monitoring-green);
}

.emStatus {
  padding: 0.2rem 0;
  color: red;
}

.statusFlexBox {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0 2rem;
}

.modbusFlexContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.modbusMinorInfo {
  font-size: 1.7rem;
}

.redEvent {
  color: red;
}

.greenEvent {
  color: var(--monitoring-green);
}

.okStatusCircle {
  color: var(--monitoring-green);
  margin: 0 0.3rem 0 0;
}

.emStatusCircle {
  color: red;
  margin: 0 0.3rem 0 0;
}

.statusSection {
  padding: 0.5rem 0;
}

.inputName {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.statusTypeHeading {
  font-size: 1.2rem;
  padding: 0 0 0.5rem 0;
}

.setPointInput input {
  padding: 1rem;
  outline: none;
  background: none;
  border-style: solid;
  border-radius: 1rem;
  color: white;
  font-size: 16px;
  border-color: white;
  text-align: center;
  margin: 1rem 0;
}

.updateBtn {
  width: 100%;
  max-width: 440px;
  background: none;
  border-style: solid;
  border-color: var(--monitoring-green);
  color: var(--monitoring-green);
  border-radius: 2rem;
  padding: 1rem 3rem;
  cursor: pointer;
  font-size: 16px;
}

.cancelUpdateBtn {
  width: 100%;
  max-width: 440px;
  background: none;
  border-style: solid;
  border-color: white;
  color: white;
  border-radius: 2rem;
  padding: 1rem 3rem;
  cursor: pointer;
  font-size: 16px;
}

.redOutlineBtn {
  width: 100%;
  max-width: 440px;
  background: none;
  border-style: solid;
  border-color: red;
  color: red;
  border-radius: 2rem;
  padding: 1rem 3rem;
  cursor: pointer;
  font-size: 16px;
}

.manageOrgs h2 {
  text-align: center;
}

.addOrgWrapper {
  width: 95%;
  max-width: 40rem;
  margin: auto;
  text-align: center;
}

.reviewBtns {
  display: flex;
  width: 100%;
  margin: auto;
  text-align: center;
}

.addOrgWrapper h2 {
  padding: 1.5rem 0;
}

.addOrgWrapper button {
  margin: 0.5rem 0;
}

.noteInput input {
  width: 100%;
  margin: auto;
  padding: 1rem;
  outline: none;
  background: none;
  border-style: solid;
  border-radius: 1rem;
  color: var(--accent-color);
  font-size: 16px;
  border-color: white;
}

.noteInput button {
  background-color: var(--monitoring-green);
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 1rem;
  padding: 0 1.5rem;
  margin: 0 0.3rem;
}

.simulatorWrapper button {
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  background-color: white;
  color: black;
  border: none;
  border-radius: 1rem;
  font-size: 2rem;
}

.eventWrapper {
  padding: 0 1rem;
}

.redEvent {
  color: red;
}

@media only screen and (max-width: 1000px) {
  .ctGrid {
    display: grid;
    grid-template-columns: 1fr;
  }
  .psiContainer {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .imageWrapper {
    width: 400px;
    height: 400px;
    margin: 0.5rem;
  }

  .tabIcon {
    font-size: 28px;
    color: white;
  }

  .selectedTabIcon {
    font-size: 28px;
    color: var(--monitoring-green);
  }

  .infoScreenToggle {
    width: 90%;
  }

  .flex-align-row-responsive {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media print {
  .nfpa-section {
    page-break-inside: avoid;
  }

  .churnTestSection {
    page-break-inside: avoid;
  }

  .hideOnPrint {
    display: none;
  }
}
